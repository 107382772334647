import * as dayjs from "dayjs";
import { graphql, navigate } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import React from 'react';
import { Helmet } from "react-helmet";
import styled from "styled-components";
import BlogPostCard from '../components/blog-post-card';
import Header from "../components/header";
import favicon from '../images/favicon.png';


// Images
const TopRightTree = styled.div`
   position: absolute;
   top: -19px;
   right: -250px;
   width: 850px;
  z-index: 0;
   @media (max-width: 768px) {
    right: -265px;
    width: 450px !important;
  }

  @media (max-width:1180px ) {
    width: 760px;
  }

  @media (max-width: 900px) {
    width: 600px;
    right: -245px;
  }
 `;

const LeftUnderHeroTree = styled.div`
    position: absolute;
    top: 650px;
    left: -380px;
    width: 915px;
    z-index: 0;

@media (max-width: 768px) {
  left: -265px;
  width: 615px;
  top: 800px;
}

@media (max-width: 1150px) {
 top: 900px; 
}
`;


// Components & Sections
const BlogPageWrapper = styled.div`
   background-color: white;
   margin: 0;
   overflow: hidden;
   position: relative;
   min-height: 100vh;
 `;

const BlogPageInner = styled.div`
    max-width: 1060px; 
    margin-left: auto;
    margin-right: auto;
    padding: 180px 80px 180px 80px;
    height: 100%;
    z-index: 10;
    position: relative;
    margin-bottom: 160px;
   @media (max-width: 900px) {
     max-width: 100%;
     padding: 0 80px 0px 80px;
     margin-top: 86px;
  }

  @media (max-width: 768px) {
     margin-top: 86px;
     padding: 0 26px 0 26px;
     z-index: 36;
  }
 `

const BlogHeadingPost = styled.div`
    display: flex;
    width: 100%;
    height: 300px;
    cursor: pointer;
    margin-top: 40px;

    @media (max-width: 768px) {
        flex-direction: column; 
        height: 100%;
    }
 `

const BlogHeadingImgDiv = styled.div`
    width: 55%;
    height: 300px;
    min-width: 300px;

    @media (max-width: 768px) {
      width: 100%;
    }
 `

const BlogHeadingImg = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
 `

const BlogHeadingText = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    max-width: 300px;

    @media (max-width: 768px) {
        margin-top: 16px;
        max-width: 100%;
        margin-left: 0px;
  
    }

    @media (max-width: 500px) {
      max-width: 300px;
    }
 `

const Subtitle = styled.p`
    font-size: 12px;
    color: #4fbc61;
    font-weight: 500;
    margin: 0;
 `

const Title = styled.h2`
    color: black;
    margin: 0;
    font-size: 32px;
    line-height: 38px;
		width: 400px;

		@media (max-width: 768px) {
			width: auto;
		}
 `

const Text = styled.p`
    color: rgba(79, 79, 79, 1);
    font-size: 14px;
    line-height: 22px;
 `

const CardsGrid = styled.div`
    display: grid;
    grid-template: auto / 1fr 1fr 1fr;
    margin-top: 160px;
    grid-gap: 26px;
    z-index: 89;
    position: relative;

    @media (max-width: 768px) {
     grid-template: auto / 1fr;
     grid-row-gap: 56px;
     margin-top: 80px;
    }

    @media (max-width: 500px) {
      grid-row-gap: 26px;
    }
`

const BlogHeadingFooter = styled.div`
  color: rgba(117, 117, 117, 1);
  font-size: 12px;
  margin-top: 16px;
`

const Tag = styled.span`
  background-color: rgba(242, 242, 242, 1);
  border-radius: 18px;
  padding: 2px 8px;
  margin-right: 16px;
  color: rgba(41, 41, 41, 1);
    
`
const BlogPage = ({ data }) => {
	const posts = data.gcms.posts;
	const navigateToPost = (slug) => {
		navigate(`/posts/${slug}`);
	}

	return (
		<BlogPageWrapper>
			<Header />
			<Helmet>
				<meta charSet="utf-8" />
				<title>SpringTree - Our tech blog</title>
				<link rel="canonical" href="https://springtree.eu" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<link rel="icon" type="image/png" href={favicon} sizes="16x16" />
				<meta name="twitter:card" content="summary"></meta>
			</Helmet>
			<TopRightTree >
				<StaticImage loading="eager" placeholder="none" src='../images/top-right-tree.png' alt="tree leaves" />
			</TopRightTree>
			<LeftUnderHeroTree>
				<StaticImage loading="eager" placeholder="none" src="../images/left-under-hero-tree.png" alt="tree leaves" />
			</LeftUnderHeroTree>

			<BlogPageInner>
				<h1>Blog</h1>
				{posts ? (
					<BlogHeadingPost onClick={() => navigateToPost(posts[0].slug)}>
						<BlogHeadingImgDiv >
							<BlogHeadingImg src={posts[0]?.coverImage.url} style={{ height: '100%', width: '100%', objectFit: "cover" }} alt="blog heading" />
						</BlogHeadingImgDiv>
						<BlogHeadingText>

							<Subtitle>{dayjs(posts[0]?.date).format("MMMM DD, YYYY")} - {posts[0]?.author.name}</Subtitle>
							<Title>{posts[0]?.title}</Title>
							<Text>{posts[0]?.excerpt}</Text>

							<BlogHeadingFooter>
								<Tag>{posts[0]?.tags[0]}</Tag>
								{posts[0]?.timeToRead} min read
							</BlogHeadingFooter>
						</BlogHeadingText>
					</BlogHeadingPost>
				) : <>Loading...</>}

				<CardsGrid>
					{posts ? posts.slice(1).map((post) => (
						<div key={post.id}>
							<BlogPostCard post={post} />
						</div>
					)) : <></>}
				</CardsGrid>

			</BlogPageInner>
		</BlogPageWrapper>
	)
}


export const postsQuery = graphql`
 query ($language: String!) {
	locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  gcms {
    posts(orderBy: createdAt_DESC) {
      id
      slug
      tags
      timeToRead
      title
      publishedAt
      excerpt
      date
      createdAt
      coverImage {
        url
      }
      author {
        name
        title
        picture {
          url
        }
        title
      }
    }
  }

}`

export default BlogPage;
