import dayjs from 'dayjs';
import React from 'react';
import styled from "styled-components";
import { navigate } from 'gatsby';

const BlogCardWrapper = styled.div`
    width: 100%;
    max-width: 250px;
    height: 400px;

    border-radius: 8px;
    cursor: pointer;
  

    @media (max-width: 768px) {
        display: grid;
        grid-template: 1fr / 1fr 0.3fr;
        height: 170px;
        grid-column-gap: 16px;
        max-width: 100%;
    }
`

const BlogCardImgContainer = styled.div`
    width: 100%;
    height: 145px;

    @media (max-width: 768px) {
        grid-column: 2/3;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`
const BlogCardImg = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;

    @media (max-width: 768px) {
        height: 100px;
        margin-top: 46px;
    }

    @media (max-width: 500px) {
        margin: 0;
        height: 65px;
        margin-top: 20px;
    }
    
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        grid-column: 1/2;
        grid-row: 1/2;
    
    }
`

const Subtitle = styled.p`
    font-size: 12px;
    color: #4fbc61;
    font-weight: 500;
    margin: 0;
    margin-top: 16px;

    @media (max-width: 768px) {
        margin-bottom: 6px;
    }

`

const Title = styled.h4`
    color: black;
    margin: 0;
    margin-top: 6px;

    @media (max-width: 768px) {
        margin: 0;
    }
 `

const Text = styled.p`
    color: rgba(40, 40, 40, 1);
    font-size: 14px;
    line-height: 22px;
    overflow: hidden;
    margin-top: 16px;

    @media (max-width: 500px) {
        display: none !important;
    }
`
const CardFooter = styled.div`
color: rgba(117, 117, 117, 1);
font-size: 12px;
`
const Tag = styled.span`
    background-color: rgba(242, 242, 242, 1);
    border-radius: 18px;
    padding: 2px 8px;
    margin-right: 16px;
    color: rgba(41, 41, 41, 1);
    
`
const BlogPostCard = ({ post }) => {
    console.log(post)
    return (
        <BlogCardWrapper onClick={() => navigate(`/posts/${post?.slug}`)}>
            <BlogCardImgContainer>
                <BlogCardImg  src={post?.coverImage?.url} />
            </BlogCardImgContainer>

            <TextContainer>
                <Subtitle>{dayjs(post.date).format("MMMM DD, YYYY")} - {post.author.name}</Subtitle>
                <Title>{post?.title}</Title>
                <Text>{post?.excerpt}</Text>
            </TextContainer>

            <CardFooter>
                <Tag>{post?.tags[0]}</Tag>
                {post?.timeToRead} min read</CardFooter>
        </BlogCardWrapper>
    )
}


export default BlogPostCard;